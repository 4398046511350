var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[_c('div',{staticStyle:{"display":"flex","justify-content":"space-between","padding-bottom":"0 !important"}},[_vm._m(0),_c('div',{staticClass:"pa-4",staticStyle:{"padding-bottom":"0 !important"}},[_c('v-btn',{staticClass:"t-btn--red-dark mr-4",on:{"click":_vm.closeDialog}},[_vm._v(" 閉じる ")]),_c('v-btn',{staticClass:"t-btn--prm",on:{"click":_vm.save}},[_vm._v(" 選択した広告施策を追加 ")])],1)]),_c('div',{staticClass:"d-flex",staticStyle:{"margin-bottom":"1rem","padding":"0 32px"}},[_c('v-text-field',{staticClass:"search-input mb-4",attrs:{"hide-details":"","label":"広告施策名、広告施策タグ","append-icon":"mdi-magnify"},model:{value:(_vm.dataSearch),callback:function ($$v) {_vm.dataSearch=$$v},expression:"dataSearch"}}),_c('div',{staticClass:"d-flex align-center mx-5"},[_c('span',{staticClass:"mr-3",staticStyle:{"font-size":"12px","color":"#000000"}},[_vm._v("広告施策期間開始日時")]),_c('v-icon',{staticStyle:{"margin-right":"3px"},attrs:{"size":"18","color":"#333333"}},[_vm._v("mdi-calendar-month")]),_c('v-menu',{attrs:{"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('DatePickerComponent',{staticClass:"select-date btn-mdi-close-custom-text-field",attrs:{"type":"text-field","date":_vm.dateFrom,"attr":{
                readonly: true,
                'append-icon': _vm.dateFrom ? 'mdi-close' : '',
                outlined: true,
                'hide-details': true,
              },"action":on,"hasBtnResetDatePicker":true,"title":_vm.dateFrom},on:{"reset":function($event){_vm.changeDateFrom(null), (_vm.dateFrom = null)}}})]}}]),model:{value:(_vm.menuDateFrom),callback:function ($$v) {_vm.menuDateFrom=$$v},expression:"menuDateFrom"}},[_c('v-date-picker',{attrs:{"no-title":"","locale":_vm.$i18n.locale,"max":_vm.dateTo},on:{"change":function($event){return _vm.changeDateFrom($event)},"input":function($event){_vm.menuDateFrom = false}},model:{value:(_vm.dateFrom),callback:function ($$v) {_vm.dateFrom=$$v},expression:"dateFrom"}})],1),_c('span',{staticClass:"mx-1 noto-sans_16px"},[_vm._v("〜")]),_c('v-icon',{staticStyle:{"margin-right":"3px"},attrs:{"size":"18","color":"#333333"}},[_vm._v("mdi-calendar-month")]),_c('v-menu',{attrs:{"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('DatePickerComponent',{staticClass:"select-date btn-mdi-close-custom-text-field",attrs:{"type":"text-field","date":_vm.dateTo,"attr":{
                readonly: true,
                'append-icon': _vm.dateTo ? 'mdi-close' : '',
                outlined: true,
                'hide-details': true,
              },"action":on,"hasBtnResetDatePicker":true,"title":_vm.dateTo},on:{"reset":function($event){_vm.changeDateTo(null), (_vm.dateTo = null)}}})]}}]),model:{value:(_vm.menuDateTo),callback:function ($$v) {_vm.menuDateTo=$$v},expression:"menuDateTo"}},[_c('v-date-picker',{attrs:{"no-title":"","locale":_vm.$i18n.locale,"min":_vm.dateFrom},on:{"change":function($event){return _vm.changeDateTo($event)},"input":function($event){_vm.menuDateTo = false}},model:{value:(_vm.dateTo),callback:function ($$v) {_vm.dateTo=$$v},expression:"dateTo"}})],1),_c('span',{staticClass:"mx-5",staticStyle:{"color":"#000000","font-size":"12px"}},[_vm._v("発送資料データ有無")]),_c('v-select',{staticClass:"select",attrs:{"items":_vm.getEnumHasAttachment,"item-value":"id","item-text":"name"},model:{value:(_vm.dataGetEnumHasAttachment),callback:function ($$v) {_vm.dataGetEnumHasAttachment=$$v},expression:"dataGetEnumHasAttachment"}}),_c('v-checkbox',{staticClass:"mx-3",attrs:{"label":"終了も表示"},model:{value:(_vm.isFinish),callback:function ($$v) {_vm.isFinish=$$v},expression:"isFinish"}})],1)],1),_c('Table',{ref:"table",attrs:{"itemsPerPage":_vm.itemsPerPage,"total":_vm.totalCount,"items":_vm.getCampaignList,"headers":_vm.headers,"attr":{
        dense: true,
        'item-key': 'id',
        id: 'table',
        class: 'mt-n4 table'
      },"itemsPerPageOptions":[10, 20, 50, 100, 200, 500],"funReset":_vm.loadList,"filter":_vm.filterData,"sortField":[],"multiSort":true},scopedSlots:_vm._u([{key:"item.select",fn:function(ref){
      var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"value":item.isSelected,"ripple":false},on:{"click":function($event){return _vm.checkCampainList(item.id)}}})]}},{key:"item.status",fn:function(ref){
      var item = ref.item;
return [(item.status === 'Ended')?_c('span',[_vm._v("対応済み")]):_c('span',[_vm._v("対応中")])]}},{key:"item.groups",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.groups.join(', ')))])]}},{key:"item.responseCount",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.computed.responseCount))])]}},{key:"item.budget",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatCurrency(item.budget)))])]}},{key:"item.contractRate",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.computed.responseCount === 0 ? 0 : item.computed.contractCount / item.computed.responseCount)+"% ("+_vm._s(item.computed.contractCount)+"/"+_vm._s(item.computed.responseCount)+")")])]}},{key:"item.contractCPA",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.computed.contractCount === 0 ? '¥0,000' : _vm.formatCurrency(item.budget / item.computed.contractCount)))])]}},{key:"item.advertisingPolicyPeriod",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDate(item.startDate)))]),_c('span',[_vm._v(" ~ ")]),_c('span',[_vm._v(_vm._s(_vm.formatDate(item.endDate)))])]}},{key:"item.shippingMaterialData",fn:function(ref){
      var item = ref.item;
return [(
            item.attachment1 === null &&
            item.attachment2 === null &&
            item.attachment3 === null
          )?_c('span',[_vm._v("なし")]):_c('span',[_vm._v("あり")])]}}],null,true)})],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"title-dialog pa-4",staticStyle:{"padding-bottom":"0 !important","padding-left":"32px !important"}},[_c('span',[_vm._v("広告施策を選択してください")])])}]

export { render, staticRenderFns }