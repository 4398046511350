<template>
  <div>
    <!-- v-btn -->
    <div
      v-if="type === 'btn'"
      class="d-flex align-center mb-1"
    >
      {{ label }}
      <v-icon v-if="icon">mdi-calendar-month</v-icon>
      <v-btn v-on="action" v-bind="{ ...attr }">
        <template v-if="date">
          {{ title }}
          <v-icon
            v-if="hasBtnResetDatePicker"
            @click="reset()"
            class="btn-mdi-close-custom"
            >mdi-close</v-icon
          >
        </template>
        <template v-else> 指定なし </template>
      </v-btn>
    </div>

    <!-- v-text-field -->
    <div
      v-if="type === 'text-field'"
      class="d-flex justify-start mr-5"
    >
      <v-icon v-if="icon">mdi-calendar-month</v-icon>
      <v-text-field
        :value="date ? date : checkTextInside"
        v-bind="{ ...attr }"
        @click:append="reset()"
        v-on="action"
        :rules="[errors]"
        class="btn-mdi-close-text-field-custom"
        :append-icon="hasBtnResetDatePicker && date ? 'mdi-close' : ''"
      >
      </v-text-field>
      <h5 v-if="label" class="text-12px mt-3 mr-3 ml-2">
        {{ label }}
      </h5>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ShowDate',
  data() {
    return {
    };
  },
  props: {
    type: String,
    date: String,
    attr: Object,
    title: String,
    action: Object,
    hasBtnResetDatePicker: {
      default: false,
      type: Boolean,
    },
    icon: {
      default: false,
      type: Boolean
    },
    label: String,
    rules: {
      default: () => [],
      type: Array
    },
    hasTextInsideInput: {
      default: false,
      type: Boolean
    },
  },
  computed: {
    errors(){
      let error = ''
      this.rules.forEach(func => {
        if(func(this.date).length > 0){
          error = func(this.date)
        }
      });
      if(error.length > 0){
        return error
      }else{
        return true
      }
    },

    checkTextInside(){
      if(this.hasTextInsideInput){
        return '指定なし'
      }else{
        return ''
      }
    }
  },
  methods: {
    reset() {
      this.$emit('reset');
    },
  },
};
</script>

<style scoped lang="scss">
</style>