<template>
  <div class="d-flex">
    <div v-for="item in dates" :key="item.id" class="d-flex align-center">
      <v-menu
        v-model="menuDatePicker[dates.indexOf(item)]"
        :close-on-content-click="false"
        :nudge-right="40"
        min-width="auto"
        offset-y
        transition="scale-transition"
      >
        <template v-slot:activator="{ on }">
          <ShowDate
            :rules="rules"
            :type="attrTagInput.type"
            :date="item.id === 1 ? dateFrom : dateTo"
            :label="item.label"
            :attr="getAttrForShowDate(item)"
            :hasBtnResetDatePicker="attrTagInput.hasBtnResetDatePicker"
            @reset="
              attrTagInput.hasBtnResetDatePicker
                ? reset(item.id, dates.indexOf(item))
                : ''
            "
            :hasTextInsideInput="hasTextInsideInput"
            :icon="icon"
            :action="on"
            :title="
              item.id === 1 ? funFormatTitle(dateFrom) : funFormatTitle(dateTo)
            "
          ></ShowDate>
          <span v-if="item.id === 1 && showTilde" class="mr-2">~</span>
        </template>

        <InputDate
          v-if="item.id === 1"
          :date="dateFrom"
          :attr="{
            'first-day-of-week': 0,
            locale: $i18n.locale,
            scrollable: true,
          }"
          @close="menuDatePicker[dates.indexOf(item)] = false"
          @change="setDateFrom"
          class="v-date-picker-custom"
        ></InputDate>

        <InputDate
          v-else
          :date="dateTo"
          :attr="{
            'first-day-of-week': 0,
            locale: $i18n.locale,
            scrollable: true,
          }"
          :min="dateFrom"
          @close="menuDatePicker[dates.indexOf(item)] = false"
          @change="setDateTo"
          class="v-date-picker-custom"
        ></InputDate>
      </v-menu>
    </div>
  </div>
</template>

<script>
import ShowDate from '@/components/Input/datePicker/ShowDate.vue';
import InputDate from '@/components/Input/datePicker/InputDate.vue';
import moment from 'moment';

export default {
  name: 'DatePickerSelectFromTo',
  data() {
    return {
      menuDatePicker: [],
    };
  },
  props: {
    rules: Array,
    dates: Array,
    dateFrom: String,
    dateTo: String,
    attrTagInput: Object,
    funFormatTitle: {
      default: (val) => {
        return val;
      },
      type: Function,
    },
    icon: {
      default: false,
      String,
    },
    hasTextInsideInput: {
      default: false,
      Boolean,
    },
    showTilde: Boolean
  },
  methods: {
    getAttrForShowDate (item) {
      return {
        ...this.attrTagInput,
        ...item.attrs
      }
    },
    setDateFrom(date) {
      if (this.dateTo && new Date(date) > new Date(this.dateTo)) {
        const newDateTo = moment(new Date(date), 'YYYY-MM-DD')
          .add(1, 'days')
          .toISOString()
          .substr(0, 10);
        this.setDateTo(newDateTo);
      }
      this.$emit('change', { id: 1, date: date });
    },

    setDateTo(date) {
      this.$emit('change', { id: 2, date: date });
    },

    reset(id, idx) {
      if (this.attrTagInput.type === 'btn') {
        this.menuDatePicker[idx] = true;
      } else {
        this.menuDatePicker[idx] = false;
      }
      this.$emit('reset', { id: id, date: '' });
    },
  },
  components: {
    ShowDate,
    InputDate,
  },
};
</script>

<style scoped lang="scss">
</style>
