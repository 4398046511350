<template>
  <div>
    <div v-if="type === 'btn'" class="d-flex align-center">
      <v-icon>mdi-calendar-month</v-icon>
      <v-btn v-on="action" v-bind="{ ...attr }" :class="className">
        <template v-if="date">
          {{ title }}
          <v-icon
            v-if="hasBtnResetDatePicker"
            @click="reset()"
            class="btn-mdi-close-custom"
            >mdi-close</v-icon
          >
        </template>
        <template v-else> 指定なし </template>
      </v-btn>
    </div>
    <div v-if="type === 'text-field'">
      <v-text-field
        :value="date ? date : '指定なし'"
        v-bind="{ ...attr }"
        @click:append="reset()"
        v-on="action"
        class="btn-mdi-close-text-field-custom"
        :append-icon="hasBtnResetDatePicker && date ? 'mdi-close' : ''"
        :class="className"
      >
      </v-text-field>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DatePicker',
  data() {
    return {};
  },
  props: {
    type: String,
    date: String,
    className: String,
    attr: Object,
    title: String,
    action: Object,
    hasBtnResetDatePicker: Boolean,
  },
  methods: {
    reset() {
      this.$emit('reset');
    },
  },
};
</script>

<style scoped lang="scss">
</style>
