<template>
  <v-dialog v-model="dialog" @click:outside="resetForm" content-class="px-5 py-4">
    <h1 class="title mb-2">ラベル印刷</h1>
    <v-select
      :items="items"
      v-model="select"
      @change="printPDF($event)"
      label="印刷開始位置を選択"
    ></v-select>
    <div class="d-flex justify-end">
      <v-btn
        class="t-btn--red-dark btn-custom mx-5"
        style="width: 80px"
        @click="closeDialog"
      >
        キャンセル
      </v-btn>
      <v-btn
        class="t-btn--prm btn-custom"
        style="width: 68px"
        @click="exportPDF"
      >
        PDF出力
      </v-btn>
    </div>
  </v-dialog>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import gql from 'graphql-tag';

export default {
  name: 'LabelPrinting',
  data() {
    return {
      select: 1,
      items: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
    };
  },
  props: {
    visible: Boolean,
    selected: Array,
  },
  computed: {
    ...mapGetters([
      'customerLengthPDF'
    ]),
    dialog: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
  },
  methods: {
    ...mapMutations([
      'setCustomerLengthPDF'
    ]),
    async printPDF(v) {
      this.select = v
      await this.setCustomerLengthPDF(v)
      this.$emit("change-arrIdEndPage")
    },
    resetForm() {
      this.select = 1
    },
    async exportPDF(){
      this.$store.commit('setLoadingOverlayShow');
      try {
        const result = await this.$apollo.query({
          query: gql`query (
    $clientIds: [Int!]!
    $startIndex: Int!
  ) {
    customerLabelPdf(
      clientIds: $clientIds
      startIndex: $startIndex
    ) {
      files {
        fileName
        downloadUrl
      }
    }
  }`, 
          variables: {
            clientIds: this.selected.map(c => c.id),
            startIndex: this.select
          }
        });

        window.open(result.data.customerLabelPdf.files[0].downloadUrl, '_blank').focus()
      } finally {
        this.$store.commit('setLoadingOverlayHide');
      }
      this.$emit('close');
    },
    closeDialog() {
      this.resetForm()
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .v-dialog {
    background-color: var(--bg_color_f8f8f8);
    max-width: 300px;
  }
  .v-input {
    margin-top: 0;
    &.w-50 {
      width: 50%;
      max-width: 50%;
    }
    &.sort-input {
      width: 180px;
    }
    &.date-text input {
      font-size: 12px;
    }
  }
  label:not(.normal):not(.v-label) {
    font-size: 10px;
  }
  .v-label {
    color: #888888 !important;
  }
}
// title
.title {
  color: #212121;
  font-family: 'Noto Sans';
  font-weight: bold;
  font-size: 28px !important;
}
// button
.btn-custom {
  height: 32px !important;
  font-size: 12px;
}
</style>
