<template>
  <div>
    <div>
      <div
        style="
          display: flex;
          justify-content: space-between;
          padding-bottom: 0 !important;
        "
      >
        <div
          class="title-dialog pa-4"
          style="padding-bottom: 0 !important; padding-left: 32px !important"
        >
          <span>広告施策を選択してください</span>
        </div>

        <div class="pa-4" style="padding-bottom: 0 !important">
          <v-btn class="t-btn--red-dark mr-4" @click="closeDialog">
            閉じる
          </v-btn>
          <v-btn class="t-btn--prm" @click="save">
            選択した広告施策を追加
          </v-btn>
        </div>
      </div>

      <div class="d-flex" style="margin-bottom: 1rem; padding: 0 32px">
        <v-text-field
          hide-details
          label="広告施策名、広告施策タグ"
          append-icon="mdi-magnify"
          class="search-input mb-4"
          v-model="dataSearch"
        ></v-text-field>
        <!-- @change="loadList" -->
        <div class="d-flex align-center mx-5">
          <!-- Date picker -->
          <span class="mr-3" style="font-size: 12px; color: #000000"
            >広告施策期間開始日時</span
          >
          <!-- :close-on-content-click="false" -->

          <v-icon size="18" color="#333333" style="margin-right: 3px"
            >mdi-calendar-month</v-icon
          >
          <v-menu
            v-model="menuDateFrom"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on }">
              <DatePickerComponent
                type="text-field"
                :date="dateFrom"
                :attr="{
                  readonly: true,
                  'append-icon': dateFrom ? 'mdi-close' : '',
                  outlined: true,
                  'hide-details': true,
                }"
                :action="on"
                @reset="changeDateFrom(null), (dateFrom = null)"
                class="select-date btn-mdi-close-custom-text-field"
                :hasBtnResetDatePicker="true"
                :title="dateFrom"
              ></DatePickerComponent>

              <!-- <v-text-field
                :value="dateFrom ? dateFrom : '指定なし'"
                readonly
                :append-icon="dateFrom ? 'mdi-close' : ''"
                v-bind="attrs"
                @click:append="changeDateFrom(null), (dateFrom = null)"
                v-on="on"
                outlined
                class="select-date btn-mdi-close-custom-text-field"
                hide-details
              >
              </v-text-field> -->
            </template>
            <v-date-picker
              @change="changeDateFrom($event)"
              v-model="dateFrom"
              no-title
              @input="menuDateFrom = false"
              :locale="$i18n.locale"
              :max="dateTo"
            ></v-date-picker>
          </v-menu>
          <span class="mx-1 noto-sans_16px">〜</span>
          <v-icon size="18" color="#333333" style="margin-right: 3px"
            >mdi-calendar-month</v-icon
          >
          <v-menu
            v-model="menuDateTo"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on }">
              <!-- <v-text-field
                :value="dateTo ? dateTo : '指定なし'"
                readonly
                :append-icon="dateTo ? 'mdi-close' : ''"
                v-bind="attrs"
                @click:append="changeDateTo(null), (dateTo = null)"
                v-on="on"
                outlined
                class="select-date btn-mdi-close-custom-text-field"
                hide-details
              >
              </v-text-field> -->
              <DatePickerComponent
                type="text-field"
                :date="dateTo"
                :attr="{
                  readonly: true,
                  'append-icon': dateTo ? 'mdi-close' : '',
                  outlined: true,
                  'hide-details': true,
                }"
                :action="on"
                @reset="changeDateTo(null), (dateTo = null)"
                class="select-date btn-mdi-close-custom-text-field"
                :hasBtnResetDatePicker="true"
                :title="dateTo"
              ></DatePickerComponent>
            </template>
            <v-date-picker
              @change="changeDateTo($event)"
              v-model="dateTo"
              no-title
              @input="menuDateTo = false"
              :locale="$i18n.locale"
              :min="dateFrom"
            ></v-date-picker>
          </v-menu>
          <!-- <div
            class="d-flex align-center"
            v-for="item in listDate"
            :key="item.id"
          >
            <span v-if="item.id === 2" class="mx-1 noto-sans_16px">〜</span>
            <v-menu
              v-model="menuDate[listDate.indexOf(item)]"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on }">
                <div>
                  <v-icon size="18" color="#333333" style="margin-right:3px"
                    >mdi-calendar-month</v-icon
                  >
                  <v-btn
                    outlined
                    small
                    color="var(--text_gray)"
                    class="bg--white"
                    v-model="item.date"
                    hide-details
                    v-on="on"
                    style="width: 72px; height:28px; font-size:10px;color: #000000"
                    >
                      <span v-if="item.date">指定なし</span>
                      <span v-else>{{ item.date }}</span>
                    </v-btn
                  >
                </div>
              </template> -->

          <!-- Date from -->
          <!-- <v-date-picker
                @change="loadList"
                v-if="item.id === 1"
                v-model="item.date"
                :first-day-of-week="0"
                :locale="$i18n.locale"
                scrollable
                @input="menuDate[listDate.indexOf(item)] = false"
                class="v-date-picker-custom"
              ></v-date-picker> -->
          <!--  -->

          <!-- Date to -->
          <!-- <v-date-picker
                @change="loadList"
                v-else
                v-model="item.date"
                :first-day-of-week="0"
                :min="listDate[0].date"
                :locale="$i18n.locale"
                scrollable
                @input="menuDate[listDate.indexOf(item)] = false"
                class="v-date-picker-custom"
              ></v-date-picker> -->
          <!--  -->
          <!-- </v-menu>
          </div> -->
          <!-- End date picker -->

          <span class="mx-5" style="color: #000000; font-size: 12px"
            >発送資料データ有無</span
          >
          <v-select
            class="select"
            :items="getEnumHasAttachment"
            item-value="id"
            item-text="name"
            v-model="dataGetEnumHasAttachment"
          >
          </v-select>
          <v-checkbox
            class="mx-3"
            label="終了も表示"
            v-model="isFinish"
          ></v-checkbox>
        </div>
      </div>
      <Table
        ref="table"
        :itemsPerPage="itemsPerPage"
        :total="totalCount"
        :items="getCampaignList"
        :headers="headers"
        :attr="{
          dense: true,
          'item-key': 'id',
          id: 'table',
          class: 'mt-n4 table'
        }"
        :itemsPerPageOptions="[10, 20, 50, 100, 200, 500]"
        :funReset="loadList"
        :filter="filterData"
        :sortField="[]"
        :multiSort="true"
      >
        <template v-slot:[`item.select`]="{ item }">
          <v-simple-checkbox
            :value="item.isSelected"
            @click="checkCampainList(item.id)"
            :ripple="false"
          ></v-simple-checkbox>
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <span v-if="item.status === 'Ended'">対応済み</span>
          <span v-else>対応中</span>
        </template>
        <template v-slot:[`item.groups`]="{ item }">
          <span>{{ item.groups.join(', ') }}</span>
        </template>
        <template v-slot:[`item.responseCount`]="{ item }">
          <span>{{ item.computed.responseCount }}</span>
        </template>
        <template v-slot:[`item.budget`]="{ item }">
          <span>{{ formatCurrency(item.budget) }}</span>
        </template>
        <template v-slot:[`item.contractRate`]="{ item }">
          <span
            >{{
              item.computed.responseCount === 0
                ? 0
                : item.computed.contractCount / item.computed.responseCount
            }}% ({{ item.computed.contractCount }}/{{
              item.computed.responseCount
            }})</span
          >
        </template>
        <template v-slot:[`item.contractCPA`]="{ item }">
          <span>{{
            item.computed.contractCount === 0
              ? '¥0,000'
              : formatCurrency(item.budget / item.computed.contractCount)
          }}</span>
        </template>
        <template v-slot:[`item.advertisingPolicyPeriod`]="{ item }">
          <span>{{ formatDate(item.startDate) }}</span>
          <span> &#126; </span>
          <span>{{ formatDate(item.endDate) }}</span>
        </template>
        <template v-slot:[`item.shippingMaterialData`]="{ item }">
          <span
            v-if="
              item.attachment1 === null &&
              item.attachment2 === null &&
              item.attachment3 === null
            "
            >なし</span
          >
          <span v-else>あり</span>
        </template>
      </Table>
    </div>
  </div>
</template>

<script>
// import { i18n } from '@/plugins/i18n';
import DatePickerComponent from '@/components/Input/DatePicker.vue';
import { currency2 } from '@/utils/filters.js';
import { mapGetters, mapMutations, mapActions } from 'vuex';
import Table from '@/components/Table/index.vue';

export default {
  name: 'AdvertisingMeasuresCustomers',
  props: {
    selectedList: Array,
  },
  data() {
    return {
      orderBy: [],
      filterDate: {
        dateFrom: null,
        dateTo: null,
      },
      menuDateFrom: false,
      dateFrom: null,
      menuDateTo: false,
      dateTo: null,
      menuDate: [],
      listDate: [
        {
          id: 1,
          date: null,
          // new Date(
          //   new Date().getFullYear(),
          //   new Date().getMonth(),
          //   new Date().getDate() + 1,
          // )
          //   .toISOString()
          //   .substr(0, 10),
        },
        {
          id: 2,
          date: null,
          // new Date(
          //   new Date().getFullYear() + 1,
          //   new Date().getMonth(),
          //   new Date().getDate(),
          // )
          //   .toISOString()
          //   .substr(0, 10),
        },
      ],
      isShowSupport: true,
      headers: [
        {
          text: '選択',
          value: 'select',
          sortable: false,
        },
        {
          text: 'ステータス',
          value: 'status',
          fieldName: 'campaign.status'
        },
        {
          text: '広告施策ID',
          value: 'id',
          fieldName: 'campaign.id',
        },
        {
          text: '広告施策名',
          value: 'name',
          fieldName: 'campaign.name',
        },
        {
          text: '広告施策タグ',
          value: 'groups',
          fieldName: 'campaign.groups',
        },
        {
          text: '広告施策顧客数',
          value: 'responseCount',
          fieldName: 'computed.responseCount',
          width: '140px',
        },
        {
          text: '成約率 (成約数/反応数)',
          value: 'contractRate',
          fieldName: 'computed.contractCount',
        },
        {
          text: '費用',
          value: 'budget',
          fieldName: 'campaign.budget',
        },
        {
          text: '成約CPA',
          value: 'contractCPA',
          sortable: false,
        },
        {
          text: '広告施策期間',
          value: 'advertisingPolicyPeriod',
          sortable: true,
          fieldName: 'campaign.startDate',
        },
        {
          text: '発送資料データ',
          value: 'shippingMaterialData',
          sortable: false,
        },
      ],
      // facilities: [],
      itemsPerPage: 10,
      totalCount: 0,
      ready: false,
      numLength: 0,
      itemStart: 0,
      itemStop: 0,
      dataSearch: '',

      idPolicy: null,
      namePolicy: null,
      // -----filter---------
      isFinish: false,
      campaignList: [],
    };
  },
  computed: {
    ...mapGetters([
      'getCampaignList',
      'getEnumHasAttachment',
      'getHasAttachmentSelected',
    ]),

    dataGetEnumHasAttachment: {
      get() {
        return this.getHasAttachmentSelected;
      },
      set(value) {
        this.setHasAttachmentSelected(value);
      },
    },

    filterData (){
      return {
        keyword: this.dataSearch,
        dateFrom: this.filterDate.dateFrom,
        dateTo: this.filterDate.dateTo,
        hasAttachment: this.getHasAttachmentSelected,
        finished: this.isFinish,
      }
    }
  },
  components: {
    DatePickerComponent,
    Table,
  },
  watch: {
    filterData: {
      deep: true,
      handler(v) {
        this.$nextTick(() => {
          this.search();
        })
      }
    },
  },
  async mounted() {
    // this.initFacilities();
    this.ready = true;
    await this.$refs.table.reset();
  },
  emits: ['onSave'],
  methods: {
    search () {
      this.$refs.table.reset()
    },
    async loadList(variables) {
      if (!this.ready) return;
      await this.handleSearch(variables);
    },

    changeDateFrom(event) {
      if (this.filterDate.dateFrom === event) {
        this.filterDate.dateFrom = null;
        this.dateFrom = null;
      } else {
        this.filterDate.dateFrom = event;
      }
    },

    changeDateTo(event) {
      if (this.filterDate.dateTo === event) {
        this.filterDate.dateTo = null;
        this.dateTo = null;
      } else {
        this.filterDate.dateTo = event;
      }
    },
    ...mapActions(['actionCampaignList', 'actionSearchCampaignList']),
    ...mapMutations(['setCampaignList', 'setHasAttachmentSelected']),

    handleSearch(variables) {
      let newIsFinish;
      if (this.isFinish === true) {
        newIsFinish = null;
      } else {
        newIsFinish = false;
      }
      variables.filter.finished = newIsFinish
      this.actionSearchCampaignList({ 
        filterData: variables.filter,
        pagination: variables.pagination,
        orderBy: variables.orderBy 
      }).then(
        ({ newCampaignList, total }) => {
          this.campaignList = newCampaignList;
          this.totalCount = total;
        },
      );
    },
    save() {
      // const searchData = {
      //   keyword: 'ASASAS',
      //   dateFrom: '2021-05-02',
      //   dateTo: '2021-05-05',
      //   hasAttachment: false,
      //   finished: false,
      // };
      // this.actionSearchCampaignList(searchData);

      this.$emit(
        'onSave',
        this.campaignList.filter((e) => e.isSelected === true),
      );
      this.closeDialog();
    },
    closeDialog() {
      this.dateFrom = null;
      this.dateTo = null;
      this.$emit('close');
    },
    formatDate(date) {
      return date.replace(/-/g, '/');
    },
    // dataSelected(item) {
    //   if (this.selected.includes(item.id) === false)
    //     this.selected.push(item.id);
    // },
    formatCurrency(amount) {
      return currency2(amount);
    },
    // isSelected(item) {
    //   return this.selectedList.some();
    // },
    checkCampainList(id) {
      const objIndex = this.campaignList.findIndex((obj) => obj.id === id);

      this.campaignList[objIndex].isSelected =
        !this.campaignList[objIndex].isSelected;
    },
  },
};
</script>

<style lang="scss" scoped>
.search-input {
  max-width: 352.39px !important;
  ::v-deep {
    .v-text-field__slot {
      label {
        color: #757575 !important;
      }
      input {
        color: #000000 !important;
      }
    }
  }
}

.title-dialog {
  font-size: 28px;
  color: #212121;
  font-weight: bold;
}

.table {
  margin-left: 16px !important;
  margin-right: 16px !important;
  margin-bottom: 6px !important;
  ::v-deep {
    .v-data-footer__pagination {
      display: none !important;
    }
    .v-data-table__wrapper {
      table {
        width: 1298px !important;
        max-height: 300px !important;
        thead {
          tr {
            height: 32px !important;
            th {
              height: 32px !important;
              font-weight: bold !important;
              color: #757575 !important;
              padding: 0 8px !important;
            }
          }
        }
        tbody {
          tr {
            height: 32px !important;
            td {
              height: 32px !important;
              color: #757575 !important;
              font-size: 14px !important;
              padding: 0 8px !important;
            }
          }
        }
      }
    }
    .v-data-footer__pagination {
      color: #000000;
    }
  }
}

.select {
  max-width: 82px !important;
  ::v-deep {
    .v-select__slot {
      label {
        color: #757575 !important;
      }
      input {
        color: #000000 !important;
      }
    }
    .v-select__selection--comma {
      font-size: 12px;
    }
  }
}

.select-date {
  ::v-deep {
    .v-input__append-inner {
      .v-input__icon {
        .v-icon {
          margin-bottom: 14px !important;
        }
      }
    }
    .v-input__control {
      .v-input__slot {
        cursor: pointer;
        min-height: 40px;
        max-width: 120px;
        .v-text-field__slot {
          input {
            color: #000000;
            font-size: 12px !important;
          }
        }
      }
    }
  }
}
</style>
