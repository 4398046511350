<template>
  <Table
    :attr="{
      dense: true,
      'item-key': 'id',
      id:'table'
    }"
    ref="table"
    :items="items"
    :headers="headers"
    :itemsPerPage="10"
    :total="total"
    :itemsPerPageOptions="[10, 20, 50, 100, 200, 500]"
    multi-sort
    showSelect
    :funReset="funReset"
    :filter="filter"
    :sortField="[]"
    @setSelected="$emit('setSelected', $event)"
  >
    <template v-slot:[`item.isCompany`]="{ item }">
      {{ item.isCompany ? '法人' : '個人' }}
    </template>
    <template v-slot:[`item.birthday`]="{ item }">
      {{ item.birthday | getAgeFromDate }}
    </template>
    <template v-slot:[`item.doNotContact`]="{ item }">
      {{ item.doNotContact ? '不可' : '' }}
    </template>
    <template v-slot:[`item.name`]="{ item }">
      {{ item.name | ellipsis }}
    </template>
    <template v-slot:[`item.purchaseRate`]="{ item }">
      {{ item.purchaseRate ? item.purchaseRate.name : '' }}
    </template>
    <template v-slot:[`item.membershipCancellationDate`]="{ item }">
      {{ item.membershipCancellationDate !== null ? '退会済み' : '' }}
    </template>
    <template v-slot:[`item.picName`]="{ item }">
      {{ item.pic ? item.pic.name : '' }}
    </template>
    <template v-slot:[`item.ShippingAddress`]="{ item }">
      <div v-if="item.mainContact">
        <span class="mr-3">{{ item.mainContact.postalCode }}</span>
        &nbsp;&nbsp;&nbsp;&nbsp;
        {{ item.mainContact.prefecture }}
        {{ item.mainContact.locality | ellipsis }}
        {{ item.mainContact.address1 | ellipsis }}
        {{ item.mainContact.address2 | ellipsis }}
      </div>
    </template>
    <template v-slot:[`item.CustomerDetails`]="{ item }">
      <v-btn icon class="t-btn--prm" target="_blank" :to="getUrl(item)">
        <v-icon> mdi-human-handsup</v-icon>
      </v-btn>
    </template>
  </Table>
</template>

<script>
import Table from '@/components/Table/index.vue';

export default {
  name: 'ClientSearchTable',
  components: {
    Table
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    filter: {
      default: () => {},
      type: Object,
    },
    total: Number,
    funReset: {
      type: Function,
      default: null,
    }
  },
  computed: {
    headers() {
      return [
        {
          text: '',
          value: 'top',
          align: 'center',
          sortable: false,
        },
        {
          text: '顧客番号',
          value: 'id',
          fieldName: 'client.id'
        },
        {
          text: '個人/法人',
          value: 'isCompany',
          fieldName: 'client.isCompany'
        },
        {
          text: '顧客名',
          value: 'name',
          sortable: false
        },
        {
          text: '年齢',
          value: 'birthday',
          fieldName: 'client.birthday',
          reversed: true
        },
        {
          text: '購入評価',
          value: 'purchaseRate',
          fieldName: 'client.purchaseRateId'
        },
        {
          text: '発送先住所',
          value: 'ShippingAddress',
          fieldName: 'mainContact.postalCode'
        },
        {
          text: '折衝不可',
          value: 'doNotContact',
          fieldName: 'client.doNotContact'
        },
        {
          text: '会員番号',
          value: 'memberId',
          fieldName: 'client.memberId'
        },
        {
          text: '退会状況',
          value: 'membershipCancellationDate',
          fieldName: 'client.membershipCancellationDate'
        },
        {
          text: '営業担当',
          value: 'picName',
          fieldName: 'pic.name',
        },
        {
          text: '顧客詳細',
          value: 'CustomerDetails',
          align: 'center',
          sortable: false,
        },
      ]
    }
  },
  methods: {
    reset () {
      this.$refs.table.reset()
    },
    
    resetPageAndSearch () {
      this.$refs.table.resetPageAndSearch()
    },

    getUrl(item) {
      if (item.type.trim() !== 'VSINTEREST') {
        return '/customer-details/' + item.id + '?typeClient=' + item.type;
      } else {
        return (
          '/customer-details/prospect-information/' +
          item.id +
          '?typeClient=' +
          item.type
        );
      }
    },
  }
};
</script>
