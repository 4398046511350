<template>
  <customer-member-tab>
    <template v-slot:content>
      <v-card max-width="1400px" class="ml-10 mt-4">
        <div class="mx-10 pt-5 mb-10">
          <h2 class="title-header my-2">検索条件</h2>
          <h2 class="text--title my-4">基本情報</h2>

          <!-- Basic information -->
          <div>
            <v-row>
              <v-col cols="2" class="float-left">
                <v-text-field
                  dense
                  hide-details
                  v-model="getName"
                  :label="$t('commons.customerName')"
                >
                </v-text-field>
              </v-col>
              <v-col cols="2" class="float-left">
                <v-text-field
                  dense
                  hide-details
                  v-model="getNameKana"
                  label="顧客名カナ"
                ></v-text-field>
              </v-col>
              <v-col cols="5" class="float-left d-flex justify-space-between">
                <v-text-field
                  dense
                  v-model="getClientIds"
                  :rules="[$rules.clientIdsComma]"
                  label="顧客番号(コンマ,区切りで複数検索)"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="2" class="float-left">
                <v-select
                  dense
                  hide-details
                  :items="itemIncludeCompany"
                  item-value="id"
                  item-text="name"
                  v-model="getIncludeCompany"
                ></v-select>
              </v-col>
              <v-col cols="2" class="float-left">
                <v-text-field
                  dense
                  v-model="getCompanyRepresentativeNameApplicant"
                  hide-details
                  label="法人代表者名"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-card class="mt-10 px-5 py-3" outlined max-width="95%">
              <div class="list-all-container my-10">
                <v-row>
                  <v-col cols="6" class="float-left">
                    <v-row>
                      <v-col cols="6" class="float-left">
                        <v-select
                          dense
                          hide-details
                          :items="[
                            { id: null, name: '全て' },
                            {
                              id: 'HomeOrAddress',
                              name: '自宅（個人）会社情報（法人）',
                            },
                            {
                              id: 'WorkplaceOrNotary',
                              name: '勤務先(個人)',
                            },
                            { id: 'Contact', name: '連絡先（個人・法人）' },
                            { id: 'Other', name: 'その他（個人・法人）' },
                          ]"
                          item-value="id"
                          item-text="name"
                          v-model="getClientContactTypeApplicant"
                          label="連絡先発送先"
                        ></v-select>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="6" class="float-left">
                        <v-text-field
                          dense
                          hide-details
                          v-model="getContactPostalCodesApplicant"
                          label="郵便番号(コンマ,区切りで複数検索)"
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" class="float-left">
                        <v-select
                          dense
                          hide-details
                          v-model="getContactPrefecturesApplicant"
                          :items="getEnumPrefeList"
                          item-text="name"
                          multiple
                          label="都道府県"
                        ></v-select>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" class="float-left">
                        <v-text-field
                          dense
                          hide-details
                          v-model="getContactLocalitiesApplicant"
                          label="市区郡(コンマ,区切りで複数検索)"
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="6" class="float-left">
                    <v-row>
                      <v-col cols="12" class="float-left">
                        <v-text-field
                          dense
                          hide-details
                          v-model="getContactAddress1Applicant"
                          label="町名・番地"
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" class="float-left">
                        <v-text-field
                          dense
                          v-model="getContactAddress2Applicant"
                          hide-details
                          label="ビル名、マンション名等"
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" class="float-left">
                        <v-text-field
                          v-model="getContactTelApplicant"
                          dense
                          hide-details
                          label="電話番号"
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" class="float-left">
                        <v-text-field
                          v-model="getContactMobileApplicant"
                          dense
                          hide-details
                          label="携帯電話番号"
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" class="float-left">
                        <v-text-field
                          v-model="getContactFaxApplicant"
                          dense
                          hide-details
                          label="FAX番号"
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" class="float-left">
                        <v-text-field
                          v-model="getCcontactEmailApplicant"
                          dense
                          hide-details
                          label="メールアドレス"
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </div>
            </v-card>
          </div>
          <!-- End Basic information -->
          <v-card max-width="96%" class="v-card-custom" tile outlined>
            <!-- Prospect information -->
            <div class="py-3">
              <h2 class="text--title my-4">見込客情報</h2>
              <v-row>
                <v-col cols="4" class="float-left">
                  <v-select
                    dense
                    hide-details
                    v-model="getPurchaseRatesApplicantSelect"
                    multiple
                    :items="enumPurchaseRateListApplicant"
                    item-text="name"
                    item-value="id"
                    label="購入評価"
                  ></v-select>
                </v-col>
                <v-col cols="4" class="float-left">
                  <v-select
                    dense
                    hide-details
                    v-model="getFurtherPurchaseRatesApplicant"
                    :items="enumPurchaseRateListApplicant"
                    multiple
                    item-text="name"
                    item-value="id"
                    label="買増評価"
                  ></v-select>
                </v-col>
                <v-col cols="4" class="float-left d-flex justify-space-between">
                  <v-select
                    dense
                    hide-details
                    v-model="getRenewPurchaseRatesApplicant"
                    :items="enumPurchaseRateListApplicant"
                    multiple
                    item-text="name"
                    item-value="id"
                    label="更新評価"
                  ></v-select>
                </v-col>
              </v-row>
              <v-checkbox
                v-model="getIncludeDoNotContactApplicant"
                label="折衝不可を含める"
                class="txt-chkbox"
                color="#13ACE0"
              ></v-checkbox>
              <v-row>
                <v-col cols="2" class="float-left">
                  <v-select
                    dense
                    hide-details
                    :items="picIdsApplicant"
                    multiple
                    v-model="getPicIdsApplicant"
                    item-text="name"
                    item-value="id"
                    label="営業担当"
                  ></v-select>
                </v-col>
              </v-row>
            </div>
            <!-- end prospect information -->

            <!-- Member information -->
            <div class="list-all-container py-3">
              <h2 class="text--title my-4">会員情報</h2>
              <v-row class="d-flex">
                <v-col cols="6" class="float-left d-flex justify-space-between">
                  <v-text-field
                    dense
                    hide-details
                    v-model="getMemberIdsApplicant"
                    label="会員番号(コンマ,区切りで複数検索)"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row class="d-flex">
                <v-col cols="3" class="float-left">
                  <v-select
                    dense
                    hide-details
                    v-model="getMembershipStatusApplicant"
                    item-text="name"
                    item-value="id"
                    :items="[
                      { id: 'All', name: '退会済みを含む' },
                      { id: 'ExceptExMember', name: '退会済みを含まない' },
                      { id: 'OnlyExMember', name: '退会済みのみ' },
                    ]"
                    label="退会状態"
                  ></v-select>
                </v-col>
                <v-col cols="6" class="float-left d-flex justify-start">
                  <DatePickerSelectFromTo
                    :dates="dateMenuMembershipCancellationDate"
                    :dateFrom="dateMenuMembershipCancellationDate[0].date"
                    :dateTo="dateMenuMembershipCancellationDate[1].date"
                    @change="updateMembershipCancelDate"
                    :attrTagInput="{
                      dense: true,
                      'hide-details': true,
                      label: '退会日',
                      className: 'color-calender',
                      type: 'text-field',
                      hasBtnResetDatePicker: true,
                    }"
                    :icon="true"
                    @reset="updateMembershipCancelDate"
                  ></DatePickerSelectFromTo>
                </v-col>
              </v-row>
            </div>
            <!-- End member information -->

            <!-- Contract information -->
            <div class="list-all-container py-3">
              <h2 class="text--title my-4">契約情報</h2>
              <v-row class="d-flex">
                <v-col cols="3" class="float-left d-flex justify-space-between">
                  <v-select
                    dense
                    hide-details
                    :items="bookingTypesListApplicant"
                    item-value="id"
                    multiple
                    item-text="name"
                    v-model="getListBookingTypesListApplicant"
                    label="所持契約"
                  ></v-select>
                </v-col>
                <v-col cols="2" class="float-left d-flex justify-space-between">
                  <v-select
                    dense
                    hide-details
                    v-model="getHasProductTypeIdsTypeApplicant"
                    :items="itemHasProductTypeIdsType"
                    item-text="name"
                    item-value="id"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row class="d-flex">
                <v-col cols="5" class="float-left d-flex justify-start">
                  <DatePickerSelectFromTo
                    :dates="dateContractInfor"
                    :dateFrom="dateContractInfor[0].date"
                    :dateTo="dateContractInfor[1].date"
                    @change="updateExpiringDate"
                    :attrTagInput="{
                      dense: true,
                      'hide-details': true,
                      label: '満了日',
                      className: 'color-calender',
                      type: 'text-field',
                      hasBtnResetDatePicker: true,
                    }"
                    :icon="true"
                    @reset="updateExpiringDate"
                  ></DatePickerSelectFromTo>
                </v-col>
                <v-col>
                  <template>
                    <v-checkbox
                      class="mt-0 txt-chkbox"
                      hide-details
                      v-model="getHasProductTypeIdsIncludeExpiredApplicant"
                      color="#13ACE0"
                      label="終了契約を含む"
                    >
                    </v-checkbox>
                  </template>
                </v-col>
              </v-row>
            </div>
            <!-- End contract information -->

            <!-- Activity information -->
            <div class="list-all-container py-3">
              <h2 class="text--title my-4">活動情報</h2>
              <v-row class="d-flex">
                <v-col cols="5" class="float-left d-flex justify-start">
                  <DatePickerSelectFromTo
                    :dates="dateActivityInformation"
                    :dateFrom="dateActivityInformation[0].date"
                    :dateTo="dateActivityInformation[1].date"
                    @change="updateLastActionDate"
                    :attrTagInput="{
                      dense: true,
                      'hide-details': true,
                      label: '折衝日',
                      className: 'color-calender',
                      type: 'text-field',
                      hasBtnResetDatePicker: true,
                    }"
                    :icon="true"
                    @reset="updateLastActionDate"
                  ></DatePickerSelectFromTo>
                </v-col>
                <v-col cols="3" class="float-left d-flex justify-space-between">
                  <v-select
                    dense
                    hide-details
                    v-model="getEnumClientActionMethodListApplicant"
                    item-text="name"
                    item-value="id"
                    multiple
                    :items="enumClientActionMethodListApplicant"
                    label="活動情報"
                  ></v-select>
                </v-col>
                <v-col cols="2" class="float-left d-flex justify-space-between">
                  <v-select
                    dense
                    hide-details
                    v-model="getHasActionMethodIdsTypeApplicant"
                    :items="itemHasActionMethodIdsType"
                    item-value="id"
                    item-text="name"
                    label="を全て含む"
                  ></v-select>
                </v-col>
                <v-col cols="2" class="float-left d-flex justify-space-between">
                  <v-select
                    dense
                    hide-details
                    v-model="actionWithActionType"
                    item-text="name"
                    item-value="id"
                    :items="[
                      { id: null, name: '指定なし' },
                      { id: true, name: '対応内容あり' },
                      { id: false, name: '対応内容なし' },
                    ]"
                    label="対応内容"
                  ></v-select>
                </v-col>
              </v-row>
            </div>
            <!-- End activity information -->

            <!-- Advertising measures -->
            <div class="list-all-container py-3">
              <h2 class="text--title my-4">広告施策(顧客)</h2>
              <v-row class="d-flex">
                <v-col cols="4" class="float-left d-flex justify-space-between">
                  <v-dialog
                    v-model="dialog"
                    width="1330px"
                    class="advertising-measures-customer-dialog"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-combobox
                        label="広告施策名"
                        v-bind="attrs"
                        v-on="on"
                        readonly
                        class="combobox"
                        :value="selectedList"
                      ></v-combobox>
                    </template>
                    <v-card height="auto" style="padding-bottom: 1rem">
                      <AdvertisingMeasuresCustomersList
                        @close="dialog = false"
                        :selectedList="selectedList"
                        @onSave="checkSelectedList"
                      />
                    </v-card>
                  </v-dialog>
                </v-col>
              </v-row>
              <v-row class="d-flex">
                <v-col cols="4" class="float-left d-flex justify-space-between">
                  <v-select
                    dense
                    v-model="getListCampaignGroupsApplicant"
                    hide-details
                    :items="enumCampaignGroupListApplicant"
                    item-value="name"
                    multiple
                    item-text="name"
                    label="広告施策タグ(multipl)"
                  ></v-select>
                </v-col>
                <v-col cols="3" class="float-left d-flex justify-space-between">
                  <v-select
                    dense
                    hide-details
                    item-value="id"
                    item-text="name"
                    v-model="getItemCampaignTimesApplicant"
                    :items="itemCampaignTimes"
                    label="広告施策回数"
                  ></v-select>
                </v-col>
              </v-row>
            </div>
            <!-- End advertising measures -->

            <!-- Experience reservation -->
            <div class="list-all-container py-3">
              <h2 class="text--title my-4">体験予約</h2>
              <v-row class="d-flex">
                <v-col cols="3" class="float-left d-flex justify-space-between">
                  <v-select
                    dense
                    hide-details
                    v-model="getHasTrialStayApplicant"
                    item-text="name"
                    item-value="id"
                    :items="itemHasTrialStayApplicant"
                    label="体験予約"
                  ></v-select>
                </v-col>
              </v-row>
            </div>
            <!-- End experience reservation -->

            <!-- Customer information Remarks -->
            <div class="list-all-container py-3">
              <h2 class="text--title my-4">顧客情報備考</h2>
              <v-row class="d-flex">
                <v-col cols="5" class="float-left d-flex justify-space-between">
                  <v-text-field
                    v-model="getRemarksApplicant"
                    dense
                    hide-details
                    label="顧客情報備考"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </div>
            <!-- End customer information Remarks -->
          </v-card>
          <div class="d-flex justify-center fixed-bottom">
            <v-btn
              href="#table"
              @click="() => { $refs.table.resetPageAndSearch() }"
              class="t-btn--prm px-16 my-5 ctrl-enter-click"
              ><span class="text-12px">検索</span></v-btn
            >
          </div>
        </div>
      </v-card>
      <div class="mx-10 mb-16">
        <div class="d-flex justify-start mb-2">
          <v-btn
            @click="downloadCsv()"
            :disabled="!checkPerUser"
            outlined
            small
            class="btn-crm-primary"
            ><span class="text-10px">選択項目をCSV出力</span></v-btn
          >
          <v-btn
            outlined
            small
            class="btn-crm-primary ml-5"
            @click="(visible = 1), setCustomerLengthPDF(0)"
            ><span class="text-10px">選択項目をラベル印刷</span></v-btn
          >
        </div>
        <client-search-table
          ref="table"
          :items="clientSearchPaginatedResponseApplicant"
          :funReset="search"
          :filter="data"
          :total="getTotalBookinglist"
          @setSelected="(v) => {selected = v}"
        />
      </div>
      <!-- End PDF -->
      <label-printing
        @change-arrIdEndPage="checkIdStartPage"
        :visible="visible === 1"
        :selected="selected"
        @close="visible = -1"
      ></label-printing>
    </template>
  </customer-member-tab>
</template>

<script>
import CustomerMemberTab from '../customerMemberTab.vue';
// import DatePickerComponent from '@/components/Input/DatePicker.vue';
import LabelPrinting from './LabelPrinting.vue';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { checkPermissionUserCurrent } from '@/utils/permissions';
import AdvertisingMeasuresCustomersList from '@/views/customer/response/AdvertisingMeasuresCustomers/AdvertisingMeasuresCustomersList.vue';
import gql from 'graphql-tag';
import { handlErrorView, getCopyErrorTextView } from '@/constants/functions';
import { downloadFile } from '@/utils/download';
import DatePickerSelectFromTo from '@/components/Input/datePicker/DatePickerSelectFromTo.vue';
import ClientSearchTable from '../common/ClientSearchTable.vue';

export default {
  components: {
    LabelPrinting,
    CustomerMemberTab,
    AdvertisingMeasuresCustomersList,
    // DatePickerComponent,
    DatePickerSelectFromTo,
    ClientSearchTable,
  },
  name: 'ApplicantAll',
  data() {
    return {
      showSelect: true,
      dataSelect: [],
      arrIdEndPage: [],
      data: {
        name: '',
        nameKana: '',
        clientIds: [],
        includeCompany: true,
        includeIndividual: true,
        companyRepresentativeName: '',
        contactAddress1: '',
        contactAddress2: '',
        contactTel: '',
        contactMobile: '',
        contactFax: '',
        contactEmail: '',
        includeDoNotContact: null,
        contactPostalCodes: [],
        contactPrefectures: [],
        contactLocalities: [],
        purchaseRates: [],
        hasTrialStay: null,
        hasActionWithActionType: null,
        remarks: '',
        // campaignIds: state.arrCampaignListApplicant,
        campaignIds: [],
        campaignGroups: [],
        campaignTimes: null,
        hasActionMethodIdsType: 'Any',
        hasActionMethodIds: null,
        lastActionDateFrom: null,
        lastActionDateTo: null,
        hasProductTypeIdsIncludeExpired: null,
        hasProductExpiringFromDate: null,
        hasProductExpiringToDate: null,
        hasProductTypeIdsType: 'All',
        hasProductTypeIds: null,
        membershipCancellationDateFrom: null,
        membershipCancellationDateTo: null,
        membershipStatus: null,
        memberIds: [],
        picIds: null,
        furtherPurchaseRates: [],
        renewPurchaseRates: [],
        clientContactType: null,
      },
      checkPerUser: checkPermissionUserCurrent(this.$router.currentRoute),
      dialog: false,
      visible: -1,
      selected: [],
      selectedList: [],
      // value Basic information
      itemIncludeCompany: [
        { id: true, name: '指定なし' },
        { id: null, name: '法人のみ' },
        { id: false, name: '個人のみ' },
      ],
      itemContactPrefectures: [{ name: '千葉県' }, { name: '岐阜県' }],
      itemHasTrialStayApplicant: [
        { id: null, name: '指定なし' },
        { id: true, name: 'あり' },
        { id: false, name: 'なし' },
      ],
      itemCampaignTimes: [
        { id: null, name: '指定なし' },
        { id: 'MoreThanOnce', name: '２回以上' },
        { id: 'Once', name: '初回' },
      ],
      itemHasActionMethodIdsType: [
        { id: 'All', name: 'を全て含む' },
        { id: 'Any', name: 'のいずれかを含む' },
      ],
      // Activity information
      menuActivityInformation: [],
      dateActivityInformation: [
        { label: 'から', id: 1, date: '' },
        { label: 'からの', id: 2, date: '' },
      ],
      // value Member information
      menuMembershipCancellationDate: [],
      dateMenuMembershipCancellationDate: [
        { label: 'から', id: 1, date: '' },
        { label: 'まで', id: 2, date: '' },
      ],
      // value Contract information
      menuContractInfor: [],
      dateContractInfor: [
        { label: 'から', id: 1, date: '' },
        { label: 'まで', id: 2, date: '' },
      ],
      itemHasProductTypeIdsType: [
        { id: 'All', name: 'を全て含む' },
        { id: 'Any', name: 'のいずれかを含む' },
      ],
      CorrespondenceContents: '指定なし',
    };
  },

  async mounted() {
    this.getListClientSearchPaginatedResponseApplicant();
    this.getEnumPurchaseRateListApplicant();
    this.getCampaignListApplicant();
    this.getEnumClientActionMethodList();
    this.getBookingTypesListApplicant();
    this.getEnumCampaignGroupListApplicant();
    this.getEnumPrefectureList();
    await this.$refs.table.reset();
  },
  computed: {
    ...mapGetters([
      'getApplicantListTake',
      'getActionWithActionType',
      'getEnumPrefeList',
      'getTotalBookinglist',
      'customerLengthPDF',
      'clientSearchPaginatedResponseApplicant',
      'nameApplicant',
      'nameKanaApplicant',
      'includeCompany',
      'companyRepresentativeNameApplicant',
      'contactAddress1Applicant',
      'contactAddress2Applicant',
      'contactMobileApplicant',
      'contactFaxApplicant',
      'contactEmailApplicant',
      'includeDoNotContactApplicant',
      'picIdsApplicant',
      'clientIdsApplicant',
      'contactPostalCodesApplicant',
      'contactPrefecturesApplicant',
      'contactLocalitiesApplicant',
      'enumPurchaseRateListApplicant',
      'arrPurchaseRatesApplicant',
      'hasTrialStayApplicant',
      'remarksApplicant',
      'campaignListApplicant',
      'campaignListGroupsApplicant',
      'listCampaignListApplicant',
      'listCampaignGroupsApplicant',
      'itemCampaignTimesApplicant',
      'hasActionMethodIdsTypeApplicant',
      'enumClientActionMethodListApplicant',
      'listEnumClientActionMethodListApplicant',
      'lastActionDateToApplicant',
      'lastActionDateFromApplicant',
      'hasProductTypeIdsIncludeExpiredApplicant',
      'hasProductExpiringFromDateApplicant',
      'hasProductExpiringToDateApplicant',
      'hasProductTypeIdsTypeApplicant',
      'bookingTypesListApplicant',
      'listBookingTypesListApplicant',
      'membershipCancellationDateFromApplicant',
      'membershipCancellationDateToApplicant',
      'membershipStatusApplicant',
      'memberIdsApplicant',
      'arrFurtherPurchaseRates',
      'arrRenewPurchaseRatesApplicant',
      'clientContactTypeApplicant',
      'enumCampaignGroupListApplicant',
    ]),
    // filter name
    getName: {
      get() {
        return this.nameApplicant;
      },
      set(value) {
        this.data.name = value;
        // this.setName(value);
      },
    },
    // filter namekana
    getNameKana: {
      get() {
        return this.nameKanaApplicant;
      },
      set(value) {
        this.data.nameKana = value;
        // this.setNameKana(value);
      },
    },
    // filter clientIds
    getClientIds: {
      get() {
        return this.clientIdsApplicant;
      },
      set(value) {
        if (value.length > 0) {
          if (value[value.length - 1] !== ',') {
            var arr = value.split(',').map(Number);
            // this.setClientIdsApplicant(arr);
            this.data.clientIds = arr;
          }
        } else {
          this.data.clientIds = [];
          // this.setClientIdsApplicant(null);
        }
      },
    },
    // filter includeCompany
    getIncludeCompany: {
      get() {
        return this.includeCompany;
      },
      set(value) {
        if (value) {
          this.data.includeCompany = true;
          this.data.includeIndividual = true;
        } else if (value === null) {
          this.data.includeCompany = true;
          this.data.includeIndividual = false;
        } else {
          this.data.includeCompany = false;
          this.data.includeIndividual = true;
        }
      },
    },
    // filter companyRepresentativeNameApplicant
    getCompanyRepresentativeNameApplicant: {
      get() {
        return this.companyRepresentativeNameApplicant;
      },
      set(value) {
        this.data.companyRepresentativeName = value;
        // this.setCompanyRepresentativeNameApplicant(value);
      },
    },
    // filter ContactAddress1Applicant
    getContactAddress1Applicant: {
      get() {
        return this.contactAddress1Applicant;
      },
      set(value) {
        this.data.contactAddress1 = value;
        // this.setContactAddress1Applicant(value);
      },
    },
    // filter contactAddress2Applicant
    getContactAddress2Applicant: {
      get() {
        return this.contactAddress2Applicant;
      },
      set(value) {
        this.data.contactAddress2 = value;
        // this.setContactAddress2Applicant(value);
      },
    },
    // filter contactTelApplicant
    getContactTelApplicant: {
      get() {
        return this.contactTelApplicant;
      },
      set(value) {
        this.data.contactTel = value;
        // this.setContactTelApplicant(value);
      },
    },
    // filter contactMobileApplicant
    getContactMobileApplicant: {
      get() {
        return this.contactMobileApplicant;
      },
      set(value) {
        this.data.contactMobile = value;
        // this.setContactMobileApplicant(value);
      },
    },
    // filter contactFaxApplicant
    getContactFaxApplicant: {
      get() {
        return this.contactFaxApplicant;
      },
      set(value) {
        this.data.contactFax = value;
        // this.setContactFaxApplicant(value);
      },
    },
    // filter contactEmailApplicant
    getCcontactEmailApplicant: {
      get() {
        return this.contactEmailApplicant;
      },
      set(value) {
        this.data.contactEmail = value;
        // this.setContactEmailApplicant(value);
      },
    },
    // filter includeDoNotContactApplicant
    getIncludeDoNotContactApplicant: {
      get() {
        return this.includeDoNotContactApplicant;
      },
      set(value) {
        this.data.includeDoNotContact = value;
        // this.setIncludeDoNotContactApplicant(value);
      },
    },
    // filter contactPostalCodesApplicant
    getContactPostalCodesApplicant: {
      get() {
        return this.contactPostalCodesApplicant;
      },
      set(value) {
        if (value.length > 0) {
          if (value[value.length - 1] !== ',') {
            var arr = value.split(',');
            this.data.contactPostalCodes = arr;
            // this.setContactPostalCodesApplicant(arr);
          }
        } else {
          this.data.contactPostalCodes = [];
          // this.setContactPostalCodesApplicant(null);
        }
      },
    },
    // filter contactPrefectures
    getContactPrefecturesApplicant: {
      get() {
        return this.contactPrefecturesApplicant;
      },
      set(value) {
        this.data.contactPrefectures = value;
        // this.setContactPrefecturesApplicant(value);
      },
    },
    // filter contactPrefectures
    getContactLocalitiesApplicant: {
      get() {
        return this.contactLocalitiesApplicant;
      },
      set(value) {
        if (value.length > 0) {
          if (value[value.length - 1] !== ',') {
            var arr = value.split(',');
            this.data.contactLocalities = arr;
            // this.setContactLocalitiesApplicant(arr);
          }
        } else {
          this.data.contactLocalities = [];
          // this.setContactLocalitiesApplicant([]);
        }
      },
    },
    // filter purchaseRatesApplicantSelect
    getPurchaseRatesApplicantSelect: {
      get() {
        return this.arrPurchaseRatesApplicant;
      },
      set(value) {
        this.data.purchaseRates = value;
        // this.setPurchaseRatesApplicantSelect(value);
      },
    },
    // filter furtherPurchaseRatesApplicant
    getFurtherPurchaseRatesApplicant: {
      get() {
        return this.arrFurtherPurchaseRates;
      },
      set(value) {
        this.data.furtherPurchaseRates = [];
        // this.setFurtherPurchaseRatesApplicantSelect(value);
      },
    },
    // filter furtherPurchaseRatesApplicant
    getRenewPurchaseRatesApplicant: {
      get() {
        return this.arrRenewPurchaseRatesApplicant;
      },
      set(value) {
        this.data.renewPurchaseRates = [];
        // this.setRenewPurchaseRatesApplicantSelect(value);
      },
    },
    // filter HasTrialStayApplicant
    getHasTrialStayApplicant: {
      get() {
        return this.hasTrialStayApplicant;
      },
      set(value) {
        this.data.hasTrialStay = value;
        // this.setHasTrialStayApplicant(value);
      },
    },
    actionWithActionType: {
      get() {
        return this.getActionWithActionType;
      },
      set(value) {
        this.data.hasActionWithActionType = value;
        // this.setActionWithActionType(value);
      },
    },
    // filter setRemarks
    getRemarksApplicant: {
      get() {
        return this.remarksApplicant;
      },
      set(value) {
        this.data.remarks = value;
        // this.setRemarksApplicant(value);
      },
    },
    // filter ListCampaignListApplicant
    getListCampaignListApplicant: {
      get() {
        return this.listCampaignListApplicant;
      },
      set(value) {
        this.data.campaignIds = value;
        // this.setListCampaignListApplicant(value);
      },
    },
    // filter listCampaignGroupsApplicant
    getListCampaignGroupsApplicant: {
      get() {
        return this.listCampaignGroupsApplicant;
      },
      set(value) {
        this.data.campaignGroups = value;
        // this.setListCampaignGroupsApplicant(value);
      },
    },
    // filter listCampaignGroupsApplicant
    getItemCampaignTimesApplicant: {
      get() {
        return this.itemCampaignTimesApplicant;
      },
      set(value) {
        this.data.campaignTimes = value;
        // this.setItemCampaignTimesApplicant(value);
      },
    },
    // filter hasActionMethodIdsType
    getHasActionMethodIdsTypeApplicant: {
      get() {
        return this.hasActionMethodIdsTypeApplicant;
      },
      set(value) {
        this.data.hasActionMethodIdsType = value;
        // this.setHasActionMethodIdsTypeApplicant(value);
      },
    },
    // filter enumClientActionMethodListApplicant
    getEnumClientActionMethodListApplicant: {
      get() {
        return this.listEnumClientActionMethodListApplicant;
      },
      set(value) {
        this.data.hasActionMethodIds = value;
        // this.setListEnumClientActionMethodListApplicant(value);
      },
    },
    // filter lastActionDateToApplicant
    getLastActionDateToApplicant: {
      get() {
        return this.lastActionDateToApplicant;
      },
      set(value) {
        this.data.lastActionDateTo = value;
        // this.setLastActionDateToApplicant(value);
      },
    },
    // filter lastActionDateFromApplicant
    getLastActionDateFromApplicant: {
      get() {
        return this.lastActionDateFromApplicant;
      },
      set(value) {
        this.data.lastActionDateFrom = value;
        // this.setLastActionDateFromApplicant(value);
      },
    },
    // filter hasProductTypeIdsIncludeExpiredApplicant
    getHasProductTypeIdsIncludeExpiredApplicant: {
      get() {
        return this.hasProductTypeIdsIncludeExpiredApplicant;
      },
      set(value) {
        this.data.hasProductTypeIdsIncludeExpired = value;
        // this.setHasProductTypeIdsIncludeExpiredApplicant(value);
      },
    },
    // filter hasProductExpiringFromDateApplicant
    getHasProductExpiringFromDateApplicant: {
      get() {
        return this.hasProductExpiringFromDateApplicant;
      },
      set(value) {
        this.data.hasProductExpiringFromDate = value;
        // this.setHasProductExpiringFromDateApplicant(value);
      },
    },
    // filter hasProductExpiringToDateApplicant
    getHasProductExpiringToDateApplicant: {
      get() {
        return this.hasProductExpiringToDateApplicant;
      },
      set(value) {
        this.data.hasProductExpiringToDate = value;
        // this.setHasProductExpiringToDateApplicant(value);
      },
    },
    // filter hasProductTypeIdsTypeApplicant
    getHasProductTypeIdsTypeApplicant: {
      get() {
        return this.hasProductTypeIdsTypeApplicant;
      },
      set(value) {
        this.data.hasProductTypeIdsType = value;
        // this.setHasProductTypeIdsTypeApplicant(value);
      },
    },
    // filter BookingTypesListApplicant
    getListBookingTypesListApplicant: {
      get() {
        return this.listBookingTypesListApplicant;
      },
      set(value) {
        this.data.hasProductTypeIds = value;
        // this.setListBookingTypesListApplicant(value);
      },
    },
    // filter membershipCancellationDateFromApplicant
    getMembershipCancellationDateFromApplicant: {
      get() {
        return this.membershipCancellationDateFromApplicant;
      },
      set(value) {
        this.data.membershipCancellationDateFrom = value;
        // this.setMembershipCancellationDateFromApplicant(value);
      },
    },
    // filter membershipCancellationDateToApplicant
    getMembershipCancellationDateToApplicant: {
      get() {
        return this.membershipCancellationDateToApplicant;
      },
      set(value) {
        this.data.membershipCancellationDateTo = value;
        // this.setMembershipCancellationDateToApplicant(value);
      },
    },
    // filter membershipStatusApplicant
    getMembershipStatusApplicant: {
      get() {
        return this.membershipStatusApplicant;
      },
      set(value) {
        this.data.membershipStatus = value;
        // this.setMembershipStatusApplicant(value);
      },
    },
    // filter memberIdsApplicant
    getMemberIdsApplicant: {
      get() {
        return this.memberIdsApplicant;
      },
      set(value) {
        if (value && value.length > 0) {
          this.data.memberIds = value.split(',').map(v => v?.trim()).filter((v) => !!v);
        } else {
          this.data.memberIds = [];
        }
      },
    },
    // filter picIdsApplicant
    getPicIdsApplicant: {
      get() {
        return this.arrPicIdsApplicant;
      },
      set(value) {
        this.data.picIds = value;
        // this.setListPicIdsApplicant(value);
      },
    },
    // filter clientContactTypeApplicant
    getClientContactTypeApplicant: {
      get() {
        return this.clientContactTypeApplicant;
      },
      set(value) {
        this.data.clientContactType = value;
        // this.setClientContactTypeApplicant(value);
      },
    }
  },
  methods: {
    handlErrorView,
    getCopyErrorTextView,
    ...mapActions([
      'getClientSearchPaginatedResponseApplicant',
      'getEnumPurchaseRateListApplicant',
      'getCampaignListApplicant',
      'getEnumClientActionMethodList',
      'getBookingTypesListApplicant',
      'getEnumCampaignGroupListApplicant',
      'getEnumPrefectureList',
    ]),
    ...mapMutations([
      'setCustomerLengthPDF',
      'setCopyErrorText',
      'setActionWithActionType',
      'setPicIdsApplicant',
      'setEnumPrefeList',
      'setBookingListTake',
      'setBookingListSkip',
      'setName',
      'setNameKana',
      'setIncludeCompany',
      'setCompanyRepresentativeNameApplicant',
      'setContactAddress1Applicant',
      'setContactAddress2Applicant',
      'setContactTelApplicant',
      'setContactMobileApplicant',
      'setContactFaxApplicant',
      'setContactEmailApplicant',
      'setIncludeDoNotContactApplicant',
      'setClientIdsApplicant',
      'setContactPostalCodesApplicant',
      'setContactPrefecturesApplicant',
      'setContactLocalitiesApplicant',
      'setPurchaseRatesApplicantSelect',
      'setHasTrialStayApplicant',
      'setRemarksApplicant',
      'setListCampaignListApplicant',
      'setListCampaignGroupsApplicant',
      'setItemCampaignTimesApplicant',
      'setHasActionMethodIdsTypeApplicant',
      'setListEnumClientActionMethodListApplicant',
      'setLastActionDateToApplicant',
      'setLastActionDateFromApplicant',
      'setHasProductTypeIdsIncludeExpiredApplicant',
      'setHasProductExpiringFromDateApplicant',
      'setHasProductExpiringToDateApplicant',
      'setHasProductTypeIdsTypeApplicant',
      'setListBookingTypesListApplicant',
      'setMembershipCancellationDateFromApplicant',
      'setMembershipCancellationDateToApplicant',
      'setMembershipStatusApplicant',
      'setMemberIdsApplicant',
      'setListPicIdsApplicant',
      'setFurtherPurchaseRatesApplicantSelect',
      'setRenewPurchaseRatesApplicantSelect',
      'setClientContactTypeApplicant',
    ]),
    downloadFile,

    async downloadCsv() {
      if (this.selected.map((e) => e.id).length > 0) {
        const query = `
              mutation ($clientIds: [Int!]!) {
                clientListCsv(clientIds: $clientIds) {
                  files {
                    fileName
                    downloadUrl
                  }
                }
              }
            `;
        const variables = {
          clientIds: this.selected.map((e) => e.id),
        };
        await this.$apollo
          .mutate({
            mutation: gql`
              ${query}
            `,
            variables: variables,
            fetchPolicy: 'no-cache',
          })
          .then((data) => {
            this.downloadFile(
              data.data.clientListCsv.files[0].downloadUrl,
              data.data.clientListCsv.files[0].fileName,
            );
          })
          .catch(async (error) => {
            this.setCopyErrorText(
              this.getCopyErrorTextView(query, variables, error.graphQLErrors),
            );
            const errorTmp = await handlErrorView(
              error.graphQLErrors,
              this.setPermissionUser,
              this.setRoleAdminUser,
            );
            if (errorTmp) {
              this.setAlertError(errorTmp, { root: true });
            } else {
              this.setAlertError('削除に失敗しました');
            }
          });
      }
    },

    async getListClientSearchPaginatedResponseApplicant() {
      const query = `
            query {
              staffList(crmRep: true, showDisabled: true) {
                id
                name
              }
            }
          `;
      await this.$apollo
        .query({
          query: gql`
            ${query}
          `,
        })
        .then((data) => {
          this.setPicIdsApplicant(data.data.staffList);
        })
        .catch(async (error) => {
          this.setCopyErrorText(
            this.getCopyErrorTextView(query, null, error.graphQLErrors),
          );
          const errorTmp = await handlErrorView(
            error.graphQLErrors,
            this.setPermissionUser,
            this.setRoleAdminUser,
          );
          if (errorTmp) {
            this.setAlertError(errorTmp, { root: true });
          }
        });
    },

    checkIdStartPage() {
      var arr = [];
      for (let i = 1; i < this.customerLengthPDF; i++) {
        arr.unshift({
          birthday: '',
          companyPicDepartment: '',
          companyPicName: '',
          companyPicTitle: '',
          contracts: '',
          doNotContact: '',
          id: '',
          isCompany: '',
          mainContact: '',
          memberId: '',
          membershipCancellationDate: '',
          name: '',
          pic: '',
          purchaseRate: '',
          type: '',
        });
      }
      this.selected.forEach((e) => {
        arr.push(e);
      });
      this.dataSelect = arr;

      for (let i = 0; i < this.customerLengthPDF; i++) {
        if (this.arrIdEndPage.length === 0) {
          this.arrIdEndPage.push(11);
        } else {
          this.arrIdEndPage.push(this.arrIdEndPage[i - 1] + 12);
        }
      }
    },

    async search(variables) {
      if(variables){
        this.setBookingListTake(variables.pagination.take);
        this.setBookingListSkip(variables.pagination.skip);
        try {
          await this.getClientSearchPaginatedResponseApplicant({
            data: this.data,
            orderBy: variables.orderBy
          });
        } finally {}
      }
    },

    checkSelectedList(arr) {
      const newArr = [];
      const newArrId = [];
      arr.forEach((element) => {
        newArr.push(element.name);
        newArrId.push(element.id);
      });
      this.selectedList = newArr;
      this.setListCampaignListApplicant(newArrId);
    },
    
    getDataView(value) {
      if (value.length > 20) {
        return value.slice(0, 20) + '...';
      } else {
        return value;
      }
    },

    updateExpiringDate(item) {
      if (item.id === 1) {
        this.dateContractInfor[0].date = item.date;
        this.getHasProductExpiringFromDateApplicant = item.date;
      } else {
        this.dateContractInfor[1].date = item.date;
        this.getHasProductExpiringToDateApplicant = item.date;
      }
    },

    updateLastActionDate(item) {
      if (item.id === 1) {
        this.dateActivityInformation[0].date = item.date;
        this.getLastActionDateFromApplicant = item.date;
      } else {
        this.dateActivityInformation[1].date = item.date;
        this.getLastActionDateToApplicant = item.date;
      }
    },

    updateMembershipCancelDate(item) {
      if (item.id === 1) {
        this.dateMenuMembershipCancellationDate[0].date = item.date;
        this.getMembershipCancellationDateFromApplicant = item.date;
      } else {
        this.dateMenuMembershipCancellationDate[1].date = item.date;
        this.getMembershipCancellationDateToApplicant = item.date;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.text--title {
  font-size: 18px;
}
.v-card-custom {
  border: 1px solid white;
}

.title-header {
  text-align: center;
  font-size: 20px;
  color: #000000;
}

::v-deep {
  .v-input__control {
    input {
      font-size: 12px;
    }
  }
  .v-select__selection {
    font-size: 10px;
  }
  .v-input__icon {
    svg {
      color: #333333;
    }
  }
  .color-calender {
    .v-icon {
      color: #333333 !important;
    }
  }
  .v-input--checkbox {
    .v-label {
      font-size: 12px !important;
    }
  }
  .float-left {
    .v-label,
    .v-select__selections {
      color: #888 !important;
      font-size: 12px !important;
    }
    .v-text-field__slot input {
      color: #888 !important;
    }
  }
  .txt-chkbox {
    .v-label {
      color: #000 !important;
      font-size: 12px !important;
    }
  }

  .align-center{
    .mr-5{
      margin-right: 0px !important;
    }
  }
}
.text-12px {
  word-break: keep-all !important;
}
div.div-postalCode {
  min-height: 22px;
  font-size: 10px;
}
div.div-address {
  min-height: 50px;
}
div#pdf {
  position: absolute;
  left: -1000000px;
  display: none;
  width: 757.13px;
  min-height: 1070.79px;
  // border: 1px solid black;
  padding: 80.13px 35.3px 82.02px 35.3px;
}
.div-item {
  // border: 1px solid black;
  border-radius: 10px;
  padding: 0px 10px;
  margin: 0px !important;
  min-height: 165px !important;
  width: 326.55 !important;
  font-size: 10px;
}
</style>
<style lang="scss">
html {
  scroll-behavior: smooth;
}
.pdf {
  &_item {
    position: relative;
    &_id {
      position: absolute;
      bottom: 15px;
      right: 15px;
    }
  }
}
@media print {
  .pdf {
    &_item {
      position: relative;
      &_id {
        position: absolute;
        bottom: 15px;
        right: 15px;
      }
    }
  }
}
</style>