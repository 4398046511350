var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Table',{ref:"table",attrs:{"attr":{
    dense: true,
    'item-key': 'id',
    id:'table'
  },"items":_vm.items,"headers":_vm.headers,"itemsPerPage":10,"total":_vm.total,"itemsPerPageOptions":[10, 20, 50, 100, 200, 500],"multi-sort":"","showSelect":"","funReset":_vm.funReset,"filter":_vm.filter,"sortField":[]},on:{"setSelected":function($event){return _vm.$emit('setSelected', $event)}},scopedSlots:_vm._u([{key:"item.isCompany",fn:function(ref){
  var item = ref.item;
return [_vm._v(" "+_vm._s(item.isCompany ? '法人' : '個人')+" ")]}},{key:"item.birthday",fn:function(ref){
  var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("getAgeFromDate")(item.birthday))+" ")]}},{key:"item.doNotContact",fn:function(ref){
  var item = ref.item;
return [_vm._v(" "+_vm._s(item.doNotContact ? '不可' : '')+" ")]}},{key:"item.name",fn:function(ref){
  var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("ellipsis")(item.name))+" ")]}},{key:"item.purchaseRate",fn:function(ref){
  var item = ref.item;
return [_vm._v(" "+_vm._s(item.purchaseRate ? item.purchaseRate.name : '')+" ")]}},{key:"item.membershipCancellationDate",fn:function(ref){
  var item = ref.item;
return [_vm._v(" "+_vm._s(item.membershipCancellationDate !== null ? '退会済み' : '')+" ")]}},{key:"item.picName",fn:function(ref){
  var item = ref.item;
return [_vm._v(" "+_vm._s(item.pic ? item.pic.name : '')+" ")]}},{key:"item.ShippingAddress",fn:function(ref){
  var item = ref.item;
return [(item.mainContact)?_c('div',[_c('span',{staticClass:"mr-3"},[_vm._v(_vm._s(item.mainContact.postalCode))]),_vm._v(" "+_vm._s(item.mainContact.prefecture)+" "+_vm._s(_vm._f("ellipsis")(item.mainContact.locality))+" "+_vm._s(_vm._f("ellipsis")(item.mainContact.address1))+" "+_vm._s(_vm._f("ellipsis")(item.mainContact.address2))+" ")]):_vm._e()]}},{key:"item.CustomerDetails",fn:function(ref){
  var item = ref.item;
return [_c('v-btn',{staticClass:"t-btn--prm",attrs:{"icon":"","target":"_blank","to":_vm.getUrl(item)}},[_c('v-icon',[_vm._v(" mdi-human-handsup")])],1)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }