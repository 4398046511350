<template>
  <div>
    <v-date-picker
      :value="date"
      @input="close"
      @change="change($event)"
      :v-bind="{ ...attr }"
      :min="min"
    ></v-date-picker>
  </div>
</template>

<script>
export default {
  name: 'InputDate',
  data() {
    return {};
  },
  props: {
    date: String,
    menuDatePicker: Array,
    dates: Array,
    item: Object,
    attr: Object,
    min: String,
    max: String,
  },
  methods: {
    change(date) {
      this.$emit('change', date);
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style scoped lang="scss">
</style>
